import React from 'react';
import PremiumServiceIcon from '../../assets/crown.svg';
type Props = {
  promoText?: string;
};
export const HeaderPromo = ({
  promoText = 'Get Wag! Premium & save on all services'
}: Props) => <a href="https://wagwalking.com/wag-premium" aria-label="wag-premium" className="bg-wagYellow flex p-2 rounded-lg items-center no-underline" data-sentry-component="HeaderPromo" data-sentry-source-file="HeaderPromo.tsx">
    <img src={PremiumServiceIcon} className="w-[36px] h-[36px] rounded-full" id="sidebar-premium-icon" alt="premium-service" />
    <p className="text-wagBrown text-sm pl-3">{promoText}</p>
  </a>;