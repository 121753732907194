import React, { Fragment } from 'react';
import clsx from 'clsx';
import FullStarIcon from '../../assets/full-star.svg';
import EmptyStarIcon from '../../assets/empty-star.svg';
import HalfStarIcon from '../../assets/half-star.svg';
import BlackStarIcon from '../../assets/black-star.svg';
import GoldenStarIcon from '../../assets/golden-star.svg';
type Props = {
  count?: number;
  blackStar?: boolean;
  className?: string;
  showHalfStar?: boolean;
  goldenStar?: boolean;
};
export const Stars = ({
  count = 5,
  blackStar = false,
  className,
  showHalfStar = false,
  goldenStar = false
}: Props) => <div className={clsx('flex h-fit', className)} data-sentry-component="Stars" data-sentry-source-file="Stars.tsx">
    {blackStar || goldenStar ? <img src={blackStar ? BlackStarIcon : GoldenStarIcon} alt={blackStar ? 'black-star-icon' : 'golden-star-icon'} width="16px" height="16px" className="mr-[3px]" /> : Array.from(Array(Math.round(5)).keys()).map(key => <Fragment key={key}>
        {key === count && showHalfStar ? <img src={HalfStarIcon} alt="half-star-icon" width="16px" height="16px" className="mr-[3px]" /> : <img src={key + 1 > count ? EmptyStarIcon : FullStarIcon} alt="star-icon" width="16px" height="16px" className="mr-[3px]" />}
      </Fragment>)}
  </div>;