'use client';

import React from 'react';
import { ChevronUpIcon, ChevronDownIcon } from 'lucide-react';
import { Root, Trigger, Content, CollapsibleProps } from '@radix-ui/react-collapsible';
import clsx from 'clsx';
import { menuItems } from './data/items';
type Props = {
  rootProps?: CollapsibleProps;
  rootClassName?: string;
  item: typeof menuItems[number];
};
const CollapsibleMenuItem = ({
  rootProps = {
    defaultOpen: true
  },
  rootClassName,
  item
}: Props) => {
  const [open, setOpen] = React.useState(false);
  return <Root defaultOpen={rootProps.defaultOpen} className={clsx('w-full bg-transparent', rootClassName)} open={open} onOpenChange={setOpen} data-sentry-element="Root" data-sentry-component="CollapsibleMenuItem" data-sentry-source-file="CollapsibleMenuItem.tsx">
      {item?.children ? <>
          <Trigger asChild>
            <button type="button" className="flex items-center justify-between !py-4 w-full">
              {item.title}
              {open ? <ChevronUpIcon className="ml-1 h-5 w-5 text-caribbeanGreen mr-1" /> : <ChevronDownIcon className="ml-1 h-5 w-5 text-caribbeanGreen mr-1" />}
            </button>
          </Trigger>
          <Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp">
            {item.children.map(child => <a key={child.title} className={clsx('no-underline text-darkGray text-left pl-4 pb-6 last:pb-8', 'first:pt-2 block w-fit hover:text-caribbeanGreen')} href={child.url}>
                {child.title}
              </a>)}
          </Content>
        </> : <div className="flex py-4">
          <a href={item.url} className="no-underline hover:text-caribbeanGreen" aria-label={item.title}>
            {item.title}
          </a>
        </div>}
    </Root>;
};
export default CollapsibleMenuItem;