'use client';

import clsx from 'clsx';
import React from 'react';
import footerMaskMobile from '../../assets/footer-mask-mobile.svg';
import footerMask from '../../assets/footer-mask.svg';
import { AppDownload } from './AppDownload';
import { FooterLogo } from './FooterLogo';
import { LegalLinks } from './LegalLinks';
import { MenuLinks } from './MenuLinks';
import { SocialLinks } from './SocialLinks';
type Props = {
  containerClassName?: string;
};
export const Footer = ({
  containerClassName
}: Props) => <footer className={clsx('relative overflow-hidden pt-[110px] md:pt-[7vw] xl:pt-[6vw]', containerClassName)} data-sentry-component="Footer" data-sentry-source-file="index.tsx">
    <picture data-sentry-element="picture" data-sentry-source-file="index.tsx">
      <source media="(max-width: 750px)" srcSet={footerMaskMobile} />
      <img className={clsx('w-full absolute top-0 h-[820px] sm:h-[900px] md:h-auto', 'max-w-[calc(100%+12px)] w-[calc(110%+12px)]')} src={footerMask} alt="footer-mask" />
    </picture>

    <div className="bg-paleBlue pb-[80px] md:pb-[30px]">
      <div className="grid md:grid-cols-12 md:grid-rows-[170px_minmax(100px,_1fr)_150px] relative text-white w-[92%] mx-auto max-w-[1150px]">
        <FooterLogo containerClassName="mb-4 md:mb-0 md:col-span-3 md:row-span-1" data-sentry-element="FooterLogo" data-sentry-source-file="index.tsx" />

        <MenuLinks containerClassName="md:col-span-8 md:row-span-4 md:col-start-5 md:mt-[84px]" data-sentry-element="MenuLinks" data-sentry-source-file="index.tsx" />

        <LegalLinks containerClassName="mt-8 md:mt-[20px] md:order-6 md:col-span-8 md:col-start-5 w-full" data-sentry-element="LegalLinks" data-sentry-source-file="index.tsx" />

        <AppDownload containerClassName="my-8 md:row-start-2 md:col-span-3 md:my-4 md:flex-col lg:flex-row" buttonClassName="md:w-full lg:w-[48%] md:mb-[6px] lg:my-0 [&_p]:whitespace-nowrap" data-sentry-element="AppDownload" data-sentry-source-file="index.tsx" />

        <SocialLinks containerClassName="md:row-start-3 md:col-span-3" data-sentry-element="SocialLinks" data-sentry-source-file="index.tsx" />

        <hr className="h-[1px] col-span-12 hidden md:block bg-slate-500 border-none mt-8" />

        <p className="text-white text-center md:text-left mt-5 md:mt-[22px] text-[12px] md:col-span-3 md:col-start-1">© 2024 Wag Labs, Inc. All rights reserved.</p>
      </div>
    </div>
  </footer>;