import React from 'react';
import clsx from 'clsx';
import AppLogo from '../../assets/app-logo.svg';
import { Stars } from './Stars';
import Link from 'next/link';
type Props = {
  title?: string;
  containerClassName?: string;
};
export const AppInstall = ({
  title = 'Wag! App',
  containerClassName
}: Props) => <div className={clsx('flex items-center justify-between max-w-[400px] border border-gray-300 rounded-md py-[6px] px-[8px]', containerClassName)} data-sentry-component="AppInstall" data-sentry-source-file="AppInstall.tsx">
    <img src={AppLogo} alt="Wag! App" className="w-[60px] h-[60px] rounded-md overflow-hidden mr-2" />
    <div className="mr-auto">
      <p className="text-charcoal font-[700]">{title}</p>
      <Stars count={5} data-sentry-element="Stars" data-sentry-source-file="AppInstall.tsx" />
      <p className="text-darkGray/60 text-sm">43k+ reviews</p>
    </div>
    <p className="text-caribbeanGreen text-md font-[700]">
      <Link href="https://wagwalking.app.link/52uv3K5W8jb" className="no-underline text-caribbeanGreen" data-sentry-element="Link" data-sentry-source-file="AppInstall.tsx">
        INSTALL
      </Link>
    </p>
  </div>;