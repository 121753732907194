import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { cn } from '../../utils/tailwind';
const buttonVariants = cva(clsx('inline-flex items-center justify-start', 'text-sm ring-offset-white transition-colors focus-visible:outline-none', 'focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2', 'disabled:pointer-events-none', 'disabled:opacity-50 cursor-pointer font-muli-bold'), {
  variants: {
    variant: {
      default: 'bg-caribbeanGreen text-white border-none rounded-lg transition-opacity ',
      calendar: clsx('text-charcaol bg-transparent hover:bg-[#E4F2EE] hover:border-caribbeanGreen', 'data-[state=open]:bg-[#E4F2EE] data-[state=open]:border-caribbeanGreen', 'data-[state=open]:outline data-[state=open]:outline-caribbeanGreen outline-transparent outline-offset-0 outline-1'),
      'booking-journey': clsx('text-[14px] text-white bg-wagBlue font-[700] font-[inherit]', 'flex justify-center items-center disabled:bg-gray300 disabled:text-gray600', 'hover:bg-wagBlueHover focus-visible:bg-wagBlue/90'),
      'booking-journey-secondary': clsx('text-[14px] text-darkGray bg-white font-[700] font-[inherit]', 'flex justify-center items-center disabled:text-darkGray/50', 'border border-gray200 hover:bg-gray100 focus-visible:bg-gray100/90', 'hover:bg-gray-50 focus-visible:bg-gray100/90'),
      'calendar-booking-journey': clsx('font-[400] text-[14px] text-gray600 rounded-md font-[inherit]'),
      'partner': clsx('text-[14px] text-white font-[700] font-[inherit] partner-caregiver-button', 'flex justify-center items-center')
    },
    size: {
      default: 'h-10 px-4 py-1',
      sm: 'h-9 rounded-md px-1',
      pill: 'px-4 py-4 rounded-full w-full mx-auto',
      lg: 'h-[50px] rounded-lg px-4',
      icon: 'h-10 w-10'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});
export type ButtonProps = {
  asChild?: boolean;
} & VariantProps<typeof buttonVariants> & React.ButtonHTMLAttributes<HTMLButtonElement>;
const TWButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp type="button" className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props} />;
});
TWButton.displayName = 'Button';
export { TWButton, buttonVariants };