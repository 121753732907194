import clsx from 'clsx';
import React from 'react';
type Props = {
  url: string;
  title: string;
};
export const CollapsibleFooterItem = ({
  url,
  title
}: Props) => <div className="flex last:mb-4 ml-4" data-sentry-component="CollapsibleFooterItem" data-sentry-source-file="CollapsibleFooterItem.tsx">
    <a className={clsx('text-left first:mt-6 md:my-4 block w-fit text-[12px]', 'decoration-slate-400', title?.includes('View all') ? 'underline text-white text-[13px]' : 'no-underline text-white/80')} href={url}>
      {title}
    </a>
  </div>;