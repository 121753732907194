import clsx from 'clsx';
import React from 'react';
import wagLogoWhite from '../../assets/wag-white.svg';
type Props = {
  containerClassName?: string;
};
export const FooterLogo = ({
  containerClassName
}: Props) => <div className={clsx('flex justify-between md:flex-col md:mb-6 md:w-fit', containerClassName)} data-sentry-component="FooterLogo" data-sentry-source-file="FooterLogo.tsx">
    <img src={wagLogoWhite} alt="wagLogoWhite" className="w-[60px] h-[30px] md:w-[110px] md:h-[54px] mb-4 md:mb-8" />
    <p className="text-white max-w-[250px] md:max-w-[220px] text-xs md:text-sm ml-3 md:ml-0">
      We believe being busy shouldn’t stop you from owning or taking care of your pet.
    </p>
  </div>;