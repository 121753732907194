import React from 'react';
import { cn } from '../../../../../../../utils/tailwind/cn';
import googlePlay from '../../../../../assets/play-store.svg';
type Props = {
  className?: string;
  link?: string;
};
export const GooglePlayButton = ({
  className,
  link = 'https://play.google.com/store/apps/details?id=com.ionicframework.wagandroid554504&hl=en_US'
}: Props) => <a className={cn('flex justify-center max-w-[134px] md:max-w-[150px] w-[44%] items-center border border-gray200/60 rounded-lg p-[5px]', 'bg-black', className)} aria-label="GET Google Play" target='_blank' href={link} data-sentry-component="GooglePlayButton" data-sentry-source-file="GooglePlayButton.tsx">
    <img src={googlePlay} alt="google-play-store" className="w-[20px] h-[20px] md:w-[24px] md:h-[24px] mr-2" />
    <div>
      <p className="text-[10px] text-white font-[500] !leading-3">GET</p>
      <p className="md:text-[16px] text-white font-[400] !leading-4">Google Play</p>
    </div>
  </a>;