import clsx from 'clsx';
import React from 'react';
import { GooglePlayButton } from '../booking-journey/components/confirmation/next-steps/GooglePlayButton';
import { AppleStoreButton } from '../booking-journey/components/confirmation/next-steps/AppleStoreButton';
import { cn } from '../../../../utils/tailwind/cn';
type Props = {
  containerClassName?: string;
  appleLink?: string;
  androidLink?: string;
  buttonClassName?: string;
};
export const AppDownload = ({
  containerClassName,
  appleLink,
  androidLink,
  buttonClassName
}: Props) => <div className={clsx('flex w-full mx-auto md:ml-0 max-w-[320px] md:max-w-[320px] justify-evenly md:justify-between', 'max-h-[42px]', containerClassName)} data-sentry-component="AppDownload" data-sentry-source-file="AppDownload.tsx">
    <AppleStoreButton link={appleLink} className={cn('w-[48%]', buttonClassName)} data-sentry-element="AppleStoreButton" data-sentry-source-file="AppDownload.tsx" />
    <GooglePlayButton link={androidLink} className={cn('w-[48%]', buttonClassName)} data-sentry-element="GooglePlayButton" data-sentry-source-file="AppDownload.tsx" />
  </div>;