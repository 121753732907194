import { BASE_URL } from '../../../../../constants';

export const footerMenuItems = [
  {
    title: 'Company',
    children: [
      {
        title: 'About Wag',
        url: 'https://wag.co/',
      },
      {
        title: 'Wag! Partners',
        url: `${BASE_URL}/partners`,
      },
      {
        title: 'Press & Investors',
        url: 'https://investors.wag.co/',
      },
      {
        title: 'Blog',
        url: `https://wagwalking.com/daily`,
      },
    ],
  },
  {
    title: 'Services',
    children: [
      {
        title: 'Walking',
        url: `${BASE_URL}/dog-walking`,
      },
      {
        title: 'Daily Drop-Ins',
        url: `${BASE_URL}/drop-ins`,
      },
      {
        title: 'Pet Sitting',
        url: `${BASE_URL}/dog-sitting`,
      },
      {
        title: 'Overnight Boarding',
        url: `${BASE_URL}/dog-boarding`,
      },
      {
        title: 'Training',
        url: `${BASE_URL}/dog-training`,
      },
      {
        title: 'Nutrition',
        url: `${BASE_URL}/wag-wellness/wellness-plans`,
      },
      {
        title: 'Wellness',
        url: `${BASE_URL}/wag-wellness/wellness-plans`,
      },
      {
        title: 'Insurance',
        url: `${BASE_URL}/wellness/pet-insurance`,
      },
      {
        title: 'View all services',
        url: `${BASE_URL}/care`,
      },
    ],
  },
  {
    title: 'Popular Locations',
    children: [
      {
        title: 'San Francisco',
        url: `${BASE_URL}/dog-walking/ca-san-francisco`,
      },
      {
        title: 'Los Angeles',
        url: `${BASE_URL}/dog-walking/ca-los-angeles`,
      },
      {
        title: 'Chicago',
        url: `${BASE_URL}/dog-walking/il-chicago`,
      },
      {
        title: 'New York',
        url: `${BASE_URL}/dog-walking/ny-new-york`,
      },
      {
        title: 'Atlanta',
        url: `${BASE_URL}/dog-walking/ga-atlanta`,
      },
      {
        title: 'Washington D.C.',
        url: `${BASE_URL}/dog-walking/dc-washington`,
      },
      {
        title: 'Dallas',
        url: `${BASE_URL}/dog-walking/tx-dallas`,
      },
      {
        title: 'Miami',
        url: `${BASE_URL}/dog-walking/fl-miami`,
      },
      {
        title: 'Philadelphia',
        url: `${BASE_URL}/dog-walking/pa-philadelphia`,
      },
      {
        title: 'View all locations',
        url: `${BASE_URL}/lifestyle/trails`,
      },
    ],
  },
  {
    title: 'Support',
    children: [
      {
        title: 'Trust and Safety',
        url: 'https://safety.wagwalking.com/',
      },
      {
        title: 'Do not share my info',
        url: 'https://safety.wagwalking.com/do-not-track',
      },
      {
        title: 'Community guidelines',
        url: 'https://safety.wagwalking.com/community-guidelines',
      },
    ],
  },
  {
    title: 'Apply',
    children: [
      {
        title: 'Become a Dog Walker',
        url: `${BASE_URL}/dog-walker`,
      },
      {
        title: 'Careers',
        url: 'https://wagwalking.bamboohr.com/careers',
      },
    ],
  },
];