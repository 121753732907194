import clsx from 'clsx';
import React from 'react';
import { socialLinks } from '../header/data/social';
type Props = {
  containerClassName?: string;
};
export const SocialLinks = ({
  containerClassName
}: Props) => <div className={clsx('flex flex-wrap w-full justify-between max-w-[250px] mx-auto md:ml-0 h-fit', containerClassName)} data-sentry-component="SocialLinks" data-sentry-source-file="SocialLinks.tsx">
    <p className="text-white font-semi-bold w-full mb-2">Connect with us</p>
    {socialLinks.map(socialLink => <a key={socialLink.title} href={socialLink.link} aria-label={`socialLink-${socialLink.title}`} className={clsx('no-underline rounded-full w-[42px] md:w-[48px] h-[42px] md:h-[48px]', 'flex items-center justify-center bg-transparent', 'hover:bg-white transition-colors overflow-hidden', '[&:hover_:first-child]:opacity-0 [&:hover_:last-child]:opacity-100')}>
        <img src={socialLink.icon} alt={socialLink.title} className="object-contain absolute opacity-100 transition-opacity" />
        <img src={socialLink.iconHover} alt={socialLink.title} className="object-contain absolute w-[18px] h-[18px] opacity-0 transition-opacity" />
      </a>)}
  </div>;