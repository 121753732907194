import React from 'react';
import { MenuIcon } from 'lucide-react';
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetTitle, SheetContentProps } from '../../../../tw-components/ui/sheet';
import { AppInstall } from './AppInstall';
import { HeaderPromo } from './HeaderPromo';
import { menuItems } from './data/items';
import CollapsibleMenuItem from './CollapsibleMenuItem';
import { cn } from '../../../../utils/tailwind/cn';
type Props = {
  sheetContentProps?: SheetContentProps;
  menuIconClassName?: string;
};
export const Menu = ({
  sheetContentProps,
  menuIconClassName
}: Props) => <Sheet data-sentry-element="Sheet" data-sentry-component="Menu" data-sentry-source-file="Menu.tsx">
    <SheetTrigger aria-label="menu-button" data-sentry-element="SheetTrigger" data-sentry-source-file="Menu.tsx">
      <MenuIcon className={cn('text-darkGray text-lg w-5 h-5', menuIconClassName)} data-sentry-element="MenuIcon" data-sentry-source-file="Menu.tsx" />
    </SheetTrigger>
    <SheetContent className="w-[86%] border-l-gray-200" {...sheetContentProps} data-sentry-element="SheetContent" data-sentry-source-file="Menu.tsx">
      <SheetHeader data-sentry-element="SheetHeader" data-sentry-source-file="Menu.tsx">
        <SheetTitle data-sentry-element="SheetTitle" data-sentry-source-file="Menu.tsx">
          <HeaderPromo data-sentry-element="HeaderPromo" data-sentry-source-file="Menu.tsx" />
        </SheetTitle>
        <div className="flex flex-col pb-3">
          {menuItems.map(item => <CollapsibleMenuItem key={item.title} rootClassName="border-b border-darkGray/20 last:border-b-0" item={item} />)}
        </div>
      </SheetHeader>
      <AppInstall data-sentry-element="AppInstall" data-sentry-source-file="Menu.tsx" />
    </SheetContent>
  </Sheet>;